
import axios from '@/utils/http'
import config from '@/config/index'


export function getShow(showId) {
  return axios({
    url: `${config.baseUrl}/shop/getShow/${showId}`,
    method: 'get'
  })
}
//预览修改的店铺头像与简介
export function getPreviewList(id){
  return axios({
    url:`${config.baseUrl}/shop/preview/${id}`,
    method:'get'
  })
}
// 获取所有上架商品的材质
export function getShopCatetory(showId) {
  return axios({
    url: `${config.baseUrl}/shop/getCatetory/${showId}`,
    method: 'get'
  })
}

// 购物车列表
export function getShoppingCart(params) {
  return axios({
    url: `${config.baseUrl}/shoppingCart/page/group/1/1000`,
    method: 'get',
    params
  })
}

// 删除购物车
export function delShoppingCart(data) {
  return axios({
    url: `${config.baseUrl}/shoppingCart`,
    method: 'delete',
    data
  })
}
// 添加购物车
export function AddShoppingCart(data) {
  return axios({
    url: `${config.baseUrl}/shoppingCart/add`,
    method: 'post',
    data
  })
}

// 创建订单
export function orderCreate(data) {
  return axios({
    url: `${config.baseUrl}/order/create`,
    method: 'post',
    data
  })
}

// 店铺列表
export function getShopPage(params) {
  return axios({
    url: `${config.baseUrl}/shop/page`,
    method: 'get',
    params
  })
}

// 根据公司id获取企业介绍
export function getCompanyIntroduce(id) {
  return axios({
    url: `${config.baseUrl}/companyIntroduce/getInfo/${id}`,
    method: 'get',
  })
}

// 获取工商信息
export function getCompanyInfo(id) {
  return axios({
    url: `${config.baseUrl}/companyCertify/getByCompany/${id}`,
    method: 'get',
  })
}

// 根据店铺id获取企业信息
export function getCompanyInfoByShopId(id) {
  return axios({
    url: `${config.baseUrl}/shop/companyInfo/${id}`,
    method: 'get',
  })
}

// 原材料详情
export function getRawMaterialDetail(id) {
  return axios({
    url: `${config.baseUrl}/rawMaterial/detail/${id}`,
    method: 'get',
  })
}
// 上传原材料详情（仅用在上传详情）
export function getRawMaterial(id){
  return axios({
    url:`${config.baseUrl}/rawMaterial/getDataById/${id}`,
    method:'get'
  })
}
// 商品分类树
export function getGoodsCategoryTree(id, type) {
  return axios({
    url: `${config.baseUrl}/goods/category/tree/${id}`,
    method: 'get',
    params: {
      broadHead: type
    }
  })
}
//获取所有招标公告
export function getBiddingList(){
  return axios({
    url:`${config.baseUrl}/bidding/list`,
    method:'get'
  })
}
//首页右侧菜单图标展示
export function getShortcut(){
  return axios({
    url:`${config.baseUrl}/shortcut/list`,
    method:'get'
  })
}
//提交议价
export function submitPrice(data){
  return axios({
    url:`${config.baseUrl}/price/confer/submit`,
    method:"post",
    data
  })
}
//确认议价
export function confirmPrice(orderId){
  return axios({
    url:`${config.baseUrl}/price/confer/confirm/${orderId}`,
    method:'POST',
  })
}
//获取成交记录数据
export function getRealData(){
  return axios({
    url:`${config.baseUrl}/order/getrealData`,
    method:'get'
  })
}

// 更新购物车选中

export function updateShoppingCart(data) {
  return axios({
    url: `${config.baseUrl}/shoppingCart/update/selected`,
    method: 'put',
    data
  })
}
export function getCategoryIdName(id){
  return axios({
    url:`${config.baseUrl}/goods/category/info/${id}`,
    method: 'get'
  })
}
//获取热销产品
export function getHotSaleGoods(companyId){
  return axios({
    url:`${config.baseUrl}/sku/hotSaleProducts/${companyId}`,
    method: 'get'
  })
}
//评论分页
export function getEvaluateList(params,data){
  return axios({
    url:`${config.baseUrl}/evaluate/page/${params.pageIndex}/${params.pageSize}`,
    method: 'get',
    params:data
  })
}

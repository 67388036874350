<template>
  <div class="headers">
    <div class="home-area">
      <div class="header-left">
        <div class="welcome">Hi，欢迎来到易缆网</div>
        <template v-if="isLogin">
          <div class="company-name">{{ userinfo.nickName }}</div>
          <div class="login-out" @click="logout">退出</div>
        </template>
        <template v-else>
          <div class="login" @click="jump('login')">请登录</div>
        </template>
        <div class="attestation" v-if="userinfo.companyStatus == 5" @click="jump('CompanyCert')">已认证</div>
        <div class="auth" v-else @click="jump('CompanyCert')">企业认证</div>
      </div>
      <div class="header-right">
        <YlRouterLink :companyStatus="userinfo.companyStatus"  style="padding-right: 10px" :to="(routes.at(0).path)" tag="div">{{ routes.at(0).title }}</YlRouterLink>
        <YlRouterLink v-for="item in routes.slice(1, -1)" :key="item.path" :to="item.path" tag="div">{{ item.title }}</YlRouterLink>
        <el-badge :value="msgNumber" :hidden="msgNumber == 0" :max="99" class="item">
          <YlRouterLink :companyStatus="userinfo.companyStatus"  style="padding-right: 10px" :to="(routes.at(-1).path)" tag="div">{{ routes.at(-1).title }}</YlRouterLink>
        </el-badge>
      </div>
    </div>
  </div>
</template>

<script>
import YlRouterLink from '@/components/YlRouterLink'
import { getMessageUnread } from "@/api/message";
import { getToken } from "@/utils/auth";
export default {
  components: {
    YlRouterLink,
  },
  data() {
    return {
      msgNumber: 0,
    };
  },
  mounted() {
    this.polling();
  },
  methods: {
    jump(name) {
      this.$router.push({ name });
    },
    logout() {
      this.$store.dispatch("user/logout");
      location.reload();
    },
    getMessageUnread() {
      if (this.isLogin) {
        getMessageUnread().then(({ data }) => {
          if (data.code == 0) {
            this.msgNumber = data.data;
          }
        });
      }
    },
    polling() {
      this.getMessageUnread();
      let mytimer = setInterval(() => {
        this.getMessageUnread();
      }, 30000);
      this.$on("hook:beforeDestroy", () => {
        clearInterval(mytimer);
        mytimer = null;
      });
    },
  },
  computed: {
    userinfo() {
      return this.$store.getters["user/userInfo"];
    },
    isLogin() {
      return this.$store.getters["user/isLogin"];
    },
    ExternalLink() {
      return this.$store.state.app.ExternalLink
    },
    routes() {
      return [
        {
          title: '企业后台',
          path: `${this.ExternalLink}/home?token=${getToken()}`
        }, 
        {
          title: '电子账户',
          path: `/finance/index`
        }, {
          title: '消息中心',
          path: `${this.ExternalLink}/message/messageCenter?token=${getToken()}`
        }
      ]
    }
  },
};
</script>

<style lang="scss" scoped>
.headers {
  background: #eee;
  height: 30px;
  min-width: 1200px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;

  .home-area {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 1200px;
    height: 100%;
    justify-content: space-between;

    .header-left {
      display: flex;

      .welcome {
        margin-right: 41px;
      }

      .company-name {
        margin-right: 47px;
        font-weight: 600;
        max-width: 160px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .login-out {
        cursor: pointer;
        margin-right: 53px;
      }

      .login {
        cursor: pointer;
        color: #e60012;
        margin-right: 47px;
      }

      .auth,
      .attestation {
        cursor: pointer;
      }
    }

    .header-right {
      display: flex;

      >div {
        cursor: pointer;
        margin-left: 30px;
      }

      a {
        color: #666666;
        text-decoration: none;
      }
    }
  }
}
</style>

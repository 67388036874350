import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import batch from './modules/batch'
import home from './modules/home'
import app from './modules/app'
import history from './modules/history'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    batch,
    home,
    app,
    history
  },
  state: {
  },
  getters: {
    // 是否个体户
    companyType: () => user.state.userInfo?.company?.type == 7,
  },
  mutations: {
  },
  actions: {
  }
})

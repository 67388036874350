<template>
  <div class="echarts-tab">
    <div class="real-time-price">
        <div class="pricing">
        <div class="price">{{ price?.realPrice || '-'}}</div>
        <div class="real-amount">实时价格</div>
      </div>
      <div class="pricing">
        <div class="price">{{ price?.todayPrice || '-' }}</div>
        <div class="real-amount">今日价格</div>
      </div>
      <div class="time-slot">
        <div v-if="day" class="date actived" style="width: 100px;">{{ day }}</div>
        <div
          class="date"
          :class="{ actived: item.id == trendCurrent }"
          v-for="item in timeSlot"
          :key="item.id"
          @click="changeTrendList(item.id)"
        >
          {{ item.text }}
        </div>
      </div>
    </div>
    <div class="bottom-picture">
      <i
        v-if="echartsName.length > 13"
        style="
          height: 30px;
          width: 30px;
          line-height: 30px;
          font-size: 16px;
          display: inline-block;
          position: absolute;
          z-index: 10;
          top: 0px;
          left: 0px;
          cursor: pointer;
          text-align: center;
        "
        class="el-icon-arrow-left"
        @click="imgLeft()"
      ></i>
      <ul class="Img_ul" ref="Img_ul">
        <li
          v-for="(item, index) in echartsName"
          :key="index"
          class="Img_li"
          :style="imgStyle"
          :class="{ active: currentIndex === index }"
          @click="changeEcharts(index, item.category, item.name)"
        >
          {{ item.name }}
        </li>
      </ul>
      <i
        v-if="echartsName.length > 13"
        style="
          height: 30px;
          width: 30px;
          line-height: 30px;
          font-size: 16px;
          display: inline-block;
          position: absolute;
          right: 0px;
          top: 0px;
          cursor: pointer;
          text-align: center;
        "
        class="el-icon-arrow-right"
        @click="imgRight()"
      ></i>
    </div>
    <priceTrend :echartData="echartsList[currentIndex]" :type="type"></priceTrend>
  </div>
</template>

<script>
import priceTrend from "@/components/priceTrend.vue";
import { getPriceTrend } from "@/api/api";
export default {
  components: {
    priceTrend,
  },
  props: {
    price: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      type: 'hh.mm',
      day: '',
      timeSlot: [
        { id: 1, text: "当日" },
        { id: 2, text: "近三日" },
        { id: 3, text: "近五日" },
      ],
      trendCurrent: 1,
      currentIndex: 0,
      echartsList: [],
      echartsName: [],
      imgActiveIndex: 0, // 当前移动图片的索引值
      chartsDistance: 0, // 移动的距离
      allDistance: 0, // 总移动距离
      begin: null,
      end: null,
    };
  },
  computed: {
    imgStyle() {
      return {
        transform: `translate3d(${this.chartsDistance}px, 0, 0)`, // 计算移动的距离(x,y,z)
      };
    },
  },
  mounted() {
    this.changeTrendList(this.trendCurrent);
  },
  methods: {
    changeTrendList(id) {
      this.trendCurrent = id;
      this.day = ''
      switch (id) {
        case 1:
          this.begin = this.$moment()
            .subtract(1, "days")
            .format("YYYY-MM-DD HH:mm:ss");
          this.end = this.$moment().format("YYYY-MM-DD HH:mm:ss");
          this.type = 'hh:mm'
          break;
        case 2:
          this.begin = this.$moment()
            .subtract(3, "days")
            .format("YYYY-MM-DD HH:mm:ss");
          this.end = this.$moment().format("YYYY-MM-DD HH:mm:ss");
          this.type = 'MM-dd hh:mm'
          break;
        case 3:
          this.begin = this.$moment()
            .subtract(5, "days")
            .utc()
            .format("YYYY-MM-DD HH:mm:ss");
          this.end = this.$moment().format("YYYY-MM-DD HH:mm:ss");
          this.type = 'MM-dd hh:mm'
          break;
        // case 4:
        //   this.begin = this.$moment()
        //     .subtract(3, "M")
        //     .format("YYYY-MM-DD HH:mm:ss");
        //   this.end = this.$moment().format("YYYY-MM-DD HH:mm:ss");
        //   break;
        // case 5:
        //   this.begin = this.$moment()
        //     .subtract(6, "M")
        //     .format("YYYY-MM-DD HH:mm:ss");
        //   this.end = this.$moment().format("YYYY-MM-DD HH:mm:ss");
        //   break;
      }
      this.getPriceTrend();
    },
    changeEcharts(index, category, categoryName) {
      this.currentIndex = index;
      this.$emit("getHistoryData", category, categoryName);
    },
    getPriceTrend() {
      getPriceTrend({ begin: this.begin, end: this.end }).then((res) => {
        let list = res.data.data;
        let obj = {};
        list.forEach((item, index) => {
          if (item.name === "沪铜") {
            obj = item;
            list.splice(index, 1);
            return;
          }
        });
        list.unshift(obj);
        this.echartsList = list;
        this.echartsName = res.data?.data?.map((item) => ({
          name: item.name,
          category: item.category,
        }));
      });
    },
    imgLeft() {
      if (this.imgActiveIndex > 0) {
        this.imgActiveIndex--;
        var index = 0;
        const temp = window.setInterval(() => {
          // 利用定时器实现图片左右移动的动画效果
          if (index < 33) {
            // 移动次数(33次)
            this.chartsDistance += 2; // 每次向左移动的距离 (移动总距离为33*this.imgDistance)
            index++;
            return;
          } else {
            window.clearInterval(temp); // 移动完清除定时器
          }
        }, 10);
      }
    },
    imgRight() {
      if (this.imgActiveIndex < this.echartsName.length - 1) {
        this.imgActiveIndex++;
      }
      if (this.imgActiveIndex <= this.echartsName.length - 3) {
        let index = 0;
        const temp = window.setInterval(() => {
          if (index < 33) {
            this.chartsDistance -= 2; // 每次向右移动的距离
            index++;
            return;
          } else {
            window.clearInterval(temp);
          }
        }, 10);
      } else if (this.imgActiveIndex > this.echartsName.length - 3) {
        this.imgActiveIndex = this.echartsName.length - 3;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.real-time-price {
  height: 26px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  font-size: 14px;
  .pricing {
    display: flex;
    .price {
      font-size: 22px;
      color: #e60012;
      margin-right: 11px;
      line-height: 24px;
    }
    .real-amount {
      font-size: 14px;
      color: #999999;
      line-height: 24px;
    }
  }
  .time-slot {
    width: 350px;
    display: flex;
    justify-content:flex-end ;
    align-items: center;
    .date {
      width: 58px;
      height: 24px;
      line-height: 24px;
      margin-right: 20px;
      background: #ffffff;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      color: #999999;
      text-align: center;
      cursor: pointer;
      &.actived {
        border: 1px solid #e60012;
        color: #e60012;
      }
    }
  }
}
.bottom-picture {
  position: relative;
}
.Img_ul {
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  // width: calc(100% - 60px);
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  list-style: none;
  margin: 0 auto;
}
.Img_li {
  margin-right: 40px;
  cursor: pointer;
  white-space: nowrap;
  &.active {
    font-size: 18px;
    font-weight: 700;
    color: #e60012;
  }
}
</style>

<template>
  <div class="user-nav">
    <div class="user-menu">
      <div class="user-company" v-if="isLogin">
        <div class="user-avatar">
          <el-avatar :size="50" :src="$store.state.user.avatar"></el-avatar>
          <div v-if="userinfo.companyStatus === 5" class="authentication"
            :style="{ backgroundImage: 'url(' + authImg + ')' }"></div>
        </div>
        <div class="com-name">{{ userinfo.nickName }}</div>
      </div>
      <div class="user-company" v-else>
        <div class="user-avatar" @click="jump('login')">
          <el-avatar :size="50" :src="circleUrl"></el-avatar>
        </div>
        <div class="login">
          <div class="welcome">欢迎来到易缆网</div>
          <div class="com-name">
            <span class="regist" @click="jump('login')">登录</span> |
            <span class="regist" @click="jump('register')">注册</span>
          </div>
        </div>
      </div>

      <div class="user-center">
        <div class="user-item-menu" @click="handleView(item.pathUrl)" v-for="item in userList" :key="item.id">
          <div class="user-pic" :style="{ background: 'url(' + item.images + ')' }"></div>
          <div class="user-hint">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="novice">
      <div class="introdu">
        <div class="title">新手入门</div>
        <div class="more" @click="getNewPlayerGuide">更多></div>
      </div>
      <div class="seller-flow"><router-link to="/userProtocol/use-help?ref=section3" tag="span">卖家上传商品流程</router-link>
      </div>
      <div class="buyer-flow"><router-link to="/userProtocol/use-help?ref=section7" tag="span">买家线上交易流程</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getShortcut
} from "@/api/shop";
import { getToken } from "@/utils/auth";
export default {
  data() {
    return {
      circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      authImg: require("@/assets/images/icon-auth.png"),
      userList: []
    }
  },
  computed: {
    userinfo() {
      return this.$store.getters["user/userInfo"];
    },
    isLogin() {
      return this.$store.getters["user/isLogin"];
    },
  },
  mounted() {
    this.getShortcut();
  },
  methods: {
    getNewPlayerGuide() {
      this.$router.push({
        name: "OccupancyConditions",
      });
    },
    getShortcut() {
      getShortcut().then((res) => {
        if (res.data.code === 0) {
          this.userList = res.data.data;
        }
      });
    },
    jump(name) {
      this.$router.push({
        name: name,
      });
    },
    handleView(pathUrl) {
      if (pathUrl.startsWith("http")){
        if(this.isLogin){
          location.href = `${pathUrl}?token=${ getToken() }`
        } else {
          location.href = `${pathUrl}`
        }
      } else {
        this.$router.push(pathUrl)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.user-nav {
  display: flex;
  flex-direction: column;

  .user-menu {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background: #fff;
    width: 200px;
    height: calc(100% - 104px);
    margin-left: 11px;
    padding: 20px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;

    .user-company {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      height: 80px;
      width: 160px;
      border-bottom: 1px solid #f4f4f4;

      .user-avatar {
        // padding-left: 10px;
        margin-right: 12px;
        position: relative;
        cursor: pointer;

        .authentication {
          position: absolute;
          left: 50%;
          top: 0;
          height: 18px;
          width: 18px;
          transform: translateX(50%);
        }
      }

      .login {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .com-name {
        margin-top: 10px;
        word-wrap: break-word;
        width: 100px;
        margin-bottom: 10px;
        overflow-wrap: break-word;
        color: #333;

        .regist {
          color: #e60012;
          cursor: pointer;
        }
      }
    }

    .user-login {
      .welcome {
        margin-top: 0px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999;
        margin-bottom: 7px;
      }

      .register {
        cursor: pointer;
        font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #1c88ed;
        margin-top: 0px;
        margin-bottom: 11px;
      }

      .login {
        cursor: pointer;
        width: 150px;
        height: 30px;
        background: #e60012;
        border-radius: 4px;
        font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        line-height: 30px;
      }
    }

    .user-center {
      height: 195px;
      margin: 0 auto;
      margin-top: 12px;
      display: flex;
      flex-wrap: wrap;
      font-size: 14px;

      .user-item-menu {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
        margin-bottom: 12px;

        .user-pic {
          height: 26px;
          width: 26px;
          margin-bottom: 9px;
        }
        &:hover{
          .user-hint{
            color: #e60012;
          }
        }
      }
    }
    
  }

  .novice {
    margin-left: 11px;
    height: 104px;
    box-sizing: border-box;
    background-image: linear-gradient(#fffafa, #ffeff1);
    padding: 15px 20px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;

    .introdu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 17px;

      .title {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }

      .more {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
        cursor: pointer;
        &:hover{
          color: #e60012;
        }
      }
    }

    .seller-flow {
      margin-bottom: 11px;
      color: #999999;
      cursor: pointer;
      &:hover{
        color: #e60012;
      }
    }

    .buyer-flow {
      color: #999999;
      cursor: pointer;
      &:hover{
        color: #e60012;
      }
    }
  }
}
</style>